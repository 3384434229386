<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>到款</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div v-if="detail" class="approval-detail-info">
            <el-button v-if="detail.state == 1" class="printing" type="primary" @click="printDialogVisible = true">
                打印
            </el-button>
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name
                            }}<i :class="{red:detail.shenpi_num>1}">(第{{ detail.shenpi_num }}次提交)</i></div>
                        <div class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <!--        <div class="approval-detail-title">-->
                <!--          <span class="approval-detail-tip">审批编号：</span-->
                <!--          ><span class="approval-detail-content">{{ detail.shenpi_no }}</span>-->
                <!--        </div>-->
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">招商申请日期：</span>
                    <span class="approval-detail-content">{{ detail.zanzhu.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">企业名称：</span>
                    <span class="approval-detail-content">{{ detail.corporate_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到账金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.amount).toFixed(6)) }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到账专项金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.self_amount).toFixed(6)) }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到账会议金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.meeting_decimal).toFixed(6))
                        }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到款日期：</span>
                    <span class="approval-detail-content">{{ detail.daokuan_date }}</span>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>
                <div class="approval-list">
                    <div class="name-container active">
                        <span>
                          {{ detail.true_name | sub_name }}
                          <i class="el-icon-success"></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发出到款通知</div>
                        </div>
                    </div>

                    <div
                        v-for="item in detail.shenpi"
                        :key="item.id"
                        class="name-container active"
                    >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  detail.state === 0 || detail.state === 4
                    ? 'el-icon-remove'
                    : detail.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ item.true_name
                                    }}<i v-if="detail.state != 0"
                                >({{ detail.state | sp_state }})</i
                                >
                                </div>
                                <div v-if="item.sp_time" class="info-time">
                                    {{ detail.sp_time | formatTime }}
                                </div>
                            </div>
                            <div v-if="detail.state === 0" class="info-desc">
                                {{ isApprovaler ? "我（" : "" }}{{ detail.state | sp_state
                                }}{{ isApprovaler ? "）" : "" }}
                            </div>
                            <div v-if="state !== 0" class="info-desc">
                                {{ detail.sp_remark }}
                            </div>
                        </div>
                    </div>

                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name"
                                >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 && detail.state !== 4 && detail.state !== 3"
                    class="approval-btn-left"
                    @click="toRevoke()"
                >撤回
                </el-button>
                <router-link :to="'/home/payment?id=' + pid">
                    <el-button
                        v-if="member_type == 1 && detail.state == 3"
                        class="approval-btn-right"
                    >重新提交
                    </el-button
                    >
                </router-link>
                <!--<el-button class="approval-btn-right" v-if="member_type == 1 && detail.state == 1 && !detail.file_name"-->
                <!--@click="subFile()">提交-->
                <!--</el-button>-->
                <el-button
                    v-if="isApprovaler && member_type == 0"
                    class="approval-btn-left"
                    @click="showDialog(2)"
                >拒绝
                </el-button>
                <el-button
                    v-if="isApprovaler && member_type == 0"
                    :disabled="requestState"
                    :loading="requestState"
                    class="approval-btn-right" @click="approvalConfirm"
                >确认知晓
                </el-button>
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/paymentDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <payment-print :detail="detail" :dialog-visible="printDialogVisible" @watchState="watchState"></payment-print>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import {getPathIndex, sub_name, ZHDate} from "../../tools";
import PaymentPrint from "./print/payment";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {ProjectInfo, PaymentPrint, ElButton},
    name: "paymentDetail",
    data() {
        return {
            pid: "",
            member_type: -1,

            isApprovaler: false,
            editVisible: false,
            requestState: false,
            state: -1,
            remark: "",
            detail: null,
            printDialogVisible: false
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "确认知晓" : "";
        },
        closeDialog() {
            this.editVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 4, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 4, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        approvalConfirm() {
            this.state = 1;
            this.remark = "确认知晓";
            this.toApproval()
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 4, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 4, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },

        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = Number(this.$route.query.type);
            this.$api.apiContent
                .getDaokuan({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    this.detail = res.data;
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },

        isApproval: function () {
            this.isApprovaler = false;
            this.editVisible = false;
            this.requestState = false
            this.detail.shenpi.map((item) => {
                if (
                    item.user_id === this.$store.state.user.mid &&
                    this.detail.state === 0
                ) {
                    this.isApprovaler = true;
                }
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待项目负责人确认";
                case 1:
                    return "已完成";
                case 3:
                    return "已驳回";
                default:
                    return "已撤销";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "确认中";
                case 3:
                case 4:
                    return "未确认";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;

                            .info-file {
                                color: #3b77e7;
                                margin-top: 12px;
                            }

                            .info-file-up {
                                position: relative;
                                width: 250px;
                                background: transparent;
                                border: 1px solid #3b77e7;
                                font-size: 12px;
                                margin-top: 12px;
                                padding-left: 50px;
                                color: #3b77e7;

                                .info-file-up-logo {
                                    position: absolute;
                                    top: 25%;
                                    left: 20px;
                                }
                            }
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-file {
                    font-size: 18px;
                    flex: 7;
                    flex-shrink: 1;
                    color: #3b77e7;
                }

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
