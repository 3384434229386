<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">到款</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">招商申请日期：</span>
                    <span class="approval-detail-content">{{ detail.zanzhu.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">企业名称：</span>
                    <span class="approval-detail-content">{{ detail.corporate_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到账金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.amount).toFixed(6))}}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到账专项金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.self_amount).toFixed(6))}}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到账会议金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.meeting_decimal).toFixed(6))}}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">到款日期：</span>
                    <span class="approval-detail-content">{{ detail.daokuan_date }}</span>
                </div>
                <div class="approval-list-container">
                    <div class="approval-list-tip">审批流程</div>
                    <div class="approval-list">
                        <div class="name-container active">
                        <span>
                          {{ detail.true_name | sub_name }}
                          <i class="el-icon-success"></i>
                        </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div class="info-time">{{ detail.add_time | formatTime }}</div>
                                </div>
                                <div class="info-desc">发出到款通知</div>
                            </div>
                        </div>

                        <div
                            v-for="item in detail.shenpi"
                            :key="item.id"
                            class="name-container active"
                        >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  detail.state === 0 || detail.state === 4
                    ? 'el-icon-remove'
                    : detail.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ item.true_name
                                        }}<i v-if="detail.state != 0"
                                    >({{ detail.state | sp_state }})</i
                                    >
                                    </div>
                                    <div v-if="item.sp_time" class="info-time">
                                        {{ detail.sp_time | formatTime }}
                                    </div>
                                </div>
                                <div v-if="detail.state === 0" class="info-desc">
                                    {{ isApprovaler ? "我（" : "" }}{{ detail.state | sp_state
                                    }}{{ isApprovaler ? "）" : "" }}
                                </div>
                                <div v-if="state !== 0" class="info-desc">
                                    {{ detail.sp_remark }}
                                </div>
                            </div>
                        </div>

                        <div class="name-container">
                            <span class="iconfont icontongzhi"></span>
                            <div class="info-container">
                                <div class="info-content">抄送人</div>
                                <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                                <div class="copy-members">
                                    <div
                                        v-for="item in detail.copy"
                                        :key="item.id"
                                        class="copy-members-name"
                                    >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                        <div>{{ item.true_name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {sub_name, ZHDate} from "../../../tools";

export default {
    name: "payment-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
    },
    methods: {
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待项目负责人确认";
                case 1:
                    return "已完成";
                case 3:
                    return "已驳回";
                default:
                    return "已撤销";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "确认中";
                case 3:
                case 4:
                    return "未确认";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;

    &::-webkit-scrollbar {
        display: none;
    }
}

#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-tip-length {
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>